// libraries
import React from 'react'
import { Link } from 'react-router-dom'

// files
import '@css/NavBar.css'

const NavBar = () => {
  return (
    <nav className="navbar">
      <Link to="/" className="nav-link">Home</Link>
      <Link to="/contact" className="nav-link">Contact</Link>
    </nav>
  )
}

export default NavBar
