// libraries
import React from 'react'

// files
import "@css/HomePage.css"

// images
import heroImage from "@images/hero-image.jpeg"
import customHome from "@images/customHome-image.jpeg"
import homeRenovation from "@images/homeRenovation-image.jpeg"
import commercialWork from "@images/commercialWork-image.jpeg"
import teamImage from "@images/team-image.jpeg"

function HomePage() {
  return (
    <div className="homePage">
      <div className="hero-section">
        <h1>Welcome to Panacea Complete Homecare Services, LLC</h1>
        <p>Your one-stop solution for all of your construction needs.</p>
        <img src={heroImage} alt="Elegant Home" className="hero-image"/>
      </div>

      <h1>Our Services</h1>
      <div className="servicesList">
        <div className="service">
          <img src={customHome} alt="Custom Home Building" className="hero-image"/>
          <h2>Custom Home Building</h2>
          <p>
            Turn your dream home into a reality with our bespoke home construction services. 
            From initial design to final touches, we ensure a seamless process and exceptional 
            quality.
          </p>
        </div>
        <div className="service">
          <img src={homeRenovation} alt="Renovations & Remodels" className="hero-image"/>
          <h2>Renovations & Remodels</h2>
          <p>
            Whether you're looking to update a single room or undertake a full home renovation, 
            our team has the expertise to bring your vision to life while respecting the original 
            architecture.
          </p>
        </div>
        <div className="service">
          <img src={commercialWork} alt="Commercial Work" className="hero-image"/>
          <h2>Commercial Work</h2>
          <p>
            We specialize in commercial construction and renovations, providing businesses with
            efficient, functional, and attractive spaces that reflect their brand and meet their
            operational needs.
          </p>
        </div>
      </div>

      <div className="companyBio">
        <h1>About Panacea Complete Homecare</h1>
        <img src={teamImage} alt="Our Team" className="hero-image"/>
        <p>
          Panacea Complete Homecare has been a leading force in home construction and renovation 
          for over a decade. Our mission is to transform your living space into a haven of comfort, 
          beauty, and efficiency. We believe in sustainable building practices, quality craftsmanship, 
          and creating value for our clients and the community.
        </p>
      </div>
    </div>
  )
}

export default HomePage
