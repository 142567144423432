// libraries
import React from 'react'
import { HashRouter as Router, Route, Routes } from 'react-router-dom'

// files
import NavBar from "@components/NavBar"
import HomePage from '@components/HomePage'
import ContactForm from '@components/ContactForm'
import Footer from '@components/Footer'
import '@css/App.css'

function App() {
  return (
    <Router>
      <div className="App">
        <NavBar/>
        <Routes>
          <Route path="/" element={<HomePage/>}/>
          <Route path="/contact" element={<ContactForm/>}/>
        </Routes>
        <Footer/>
      </div>
    </Router>
  )
}

export default App
