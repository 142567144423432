// libraries
import React from 'react'

// files
import '@css/Footer.css'

const Footer = () => {
  return (
    <footer className="siteFooter">
      <div className="footerContent">
        <p>© {new Date().getFullYear()} Panacea Complete Homecare. All rights reserved.</p>
        <div className="socialLinks">
          <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">Twitter</a>
          | <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">Facebook</a>
          | <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">Instagram</a>
        </div>
      </div>
    </footer>
  )
}

export default Footer
